import React from "react";
import styles from "./Bio.module.css";
import { getGravatarURL } from "../utils/getGravatarURL";

function Bio(props) {
  let photoURL = getGravatarURL({
    email: "imagetext.xyz@gmail.com",
    size: 56,
  });

  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ""}
    `}
    >
      <img src={photoURL} alt="imagetext.xyz logo" />
      <p>
        Unlocking the Power of OCR Website(imagetext.xyz): Streamline Tasks and
        Increase Productivity.
      </p>
    </div>
  );
}

export default Bio;
