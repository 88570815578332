export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "imagetext.xyz blogs",

  author: "ImageText.xyz Support",
  description:
    "Unlocking the Power of OCR Website(imagetext.xyz): Streamline Tasks and Increase Productivity",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
};
